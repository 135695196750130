import check, { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canCreate,
  canFind,
  canFindById,
  canTrimFields,
  canUpdate,
} from "./features/apiResource";

const productEndpoints = {
  categoryCatalog: "catalog/product/category",
  available: `${endpoints.product}/available`,
  order: `${endpoints.product}/order`,
  summary: `${endpoints.product}/order/summary`,
  activate: `${endpoints.product}/:id/available`,
  deactivate: `${endpoints.product}/:id/unavailable`,
};

const productApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["name", "currency"],
    baseUrl: `${clubPrefix}${endpoints.product}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canCreate(state),
    canUpdate(state),
    canFind(state),
    canFindById(state),
    {
      create(newResource) {
        const trimmedData = state.trimFields(newResource);

        return state.http.post(state.baseUrl, {
          ...trimmedData,
          clubIdList: [clubId],
        });
      },
      findByClubId(clubId, { page, limit, extraParams, sort }) {
        assert.number(clubId, "clubId must be a number");
        return state.find({
          page,
          limit,
          sort,
          extraParams: {
            ...extraParams,
            "clubList.id": clubId,
          },
        });
      },

      findAvailableByClubId(clubId, { page, limit, extraParams, sort }) {
        assert.number(clubId, "clubId must be a number");
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${productEndpoints.available}/${clubId}`,
          config,
        );
      },

      async fetchCategories() {
        const result = await state.http.put(
          state.baseUrl + "/category/search",
          { page: 0, size: 1000 },
        );
        result.data = result.data.content;
        return result;
      },

      findCategories({ page = 0, limit = 10 }) {
        assert.number(page, "page must be number");
        assert.number(limit, "limit must be number");

        const config = {
          page,
          size: limit,
        };

        return state.http.put(state.baseUrl + "/category/search", config);
      },

      deactivate(id) {
        const url = `${clubPrefix}${productEndpoints.deactivate}`.replace(
          ":id",
          id,
        );

        return state.http.patch(url);
      },

      activate(id) {
        const url = `${clubPrefix}${productEndpoints.activate}`.replace(
          ":id",
          id,
        );

        return state.http.patch(url);
      },

      order({ userMemberId, paymentMethodId, products, promotionCode, priceOverride }) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.maybe.string(
          paymentMethodId,
          "paymentMethodId must be a string",
        );
        assert.maybe.number(priceOverride, "priceOverride must be a number");
        assert.nonEmptyArray(products, "products must be a non empty array");
        assert(
          check.map(products, (p) =>
            check.like(p, { productId: 1, quantity: 1 }),
          ),
          "products must be composed of valid products. Each must have a productId and quantity fields with numbers.",
        );
        assert.maybe.string(promotionCode, "promotionCode must be a string");

        return state.http.post(`${clubPrefix}${productEndpoints.order}`, {
          userMemberId,
          paymentMethodId,
          productOrderList: products,
          promotionCode,
          priceOverride
        });
      },

      summary({ products, promotionCode, userMemberId }) {
        assert.array(products, "products must be an array");
        assert.maybe.string(promotionCode, "promotion code must be a string");
        assert.maybe.string(userMemberId, "userMemberId must be a string");

        return state.http.post(`${clubPrefix}${productEndpoints.summary}`, {
          productOrderList: products,
          promotionCode,
          userMemberId,
        });
      },
    },
  );
};

export default productApi;
