import {
  calendarQueryKeys,
  eventOccurrenceQueryKeys,
  useEventOccurrence,
  useEventOccurrenceRsvp,
  useInfiniteQueryCalendarEventOccurrences,
} from "@gymflow/api";
import {
  AsyncButton,
  DATE_FORMAT_WITH_SECONDS,
  EventCategoryFilter,
  GuestStatus,
  NotificationContext,
} from "@gymflow/common";
import { EventRsvpStatus } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import placeholderAvatar from "../../../assets/img/placeholder.jpg";
import useGymflowModels from "../../store";
import AddUserInput from "./AddUserInput";
import EventCard from "./EventCard";
import EventSelect from "./EventSelect";

function CheckIn() {
  const { api, settingsStore } = useGymflowModels();
  const { timezone } = useStoreState(settingsStore);
  const { notifyDanger } = useContext(NotificationContext);
  const [filter, setFilter] = useState<{
    category: { value: number; label: string } | null;
  }>({ category: null });
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  const {
    data: eventList,
    hasNextPage: hasMoreOccurrences,
    fetchNextPage: fetchNextOccurrences,
  } = useInfiniteQueryCalendarEventOccurrences({
    api,
    tz: timezone,
    filters: {
      dateFrom: moment().startOf("day").format(DATE_FORMAT_WITH_SECONDS),
      dateTo: moment().endOf("day").format(DATE_FORMAT_WITH_SECONDS),
      activityCategoryId: filter?.category
        ? [filter?.category.value]
        : undefined,
      limit: 100,
    },
  });

  useEffect(() => {
    if (hasMoreOccurrences) {
      fetchNextOccurrences();
    }
  }, [hasMoreOccurrences, eventList?.pageParams.length, fetchNextOccurrences]);
  const { data: event, isFetching } = useEventOccurrence({
    api,
    eventId: selectedEventId,
  });
  const { updateRsvp, addAttendeeToRsvp } = useEventOccurrenceRsvp({ api });

  useEffect(() => {
    if (!eventList) {
      return;
    }
    const notFinished = eventList.pages
      ?.flatMap((p) => p)
      .filter(
        ({ endDate, bookable }) =>
          moment.tz(endDate, timezone).isAfter(moment()) && bookable,
      )
      .sort(
        (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf(),
      );
    if (notFinished.length > 0) {
      setSelectedEventId(notFinished[0].eventOccurrenceId);
    } else {
      setSelectedEventId(null);
    }
  }, [eventList, timezone]);

  const attending = useMemo(() => {
    if (!event) {
      return [];
    }
    return event.eventRsvpList.filter(
      (rsvp) => rsvp.status !== GuestStatus.Cancelled,
    );
  }, [event]);

  const queryClient = useQueryClient();
  const invalidateEventList = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: calendarQueryKeys.all() });
  }, [queryClient]);
  const invalidateEvent = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: eventOccurrenceQueryKeys.details(selectedEventId),
    });
  }, [queryClient, selectedEventId]);

  useEffect(() => {
    const twoMinutes = 120000;
    const intervalId = setInterval(invalidateEventList, twoMinutes);

    return () => clearInterval(intervalId);
  }, [invalidateEventList]);

  useEffect(() => {
    const storedCategoryId = localStorage.getItem("kiosk_category_id");
    const storedCategoryLabel = localStorage.getItem("kiosk_category_label");
    if (storedCategoryId && storedCategoryLabel) {
      setFilter((filters) => ({
        ...filters,
        category: {
          value: +storedCategoryId,
          label: storedCategoryLabel,
        },
      }));
    }
  }, []);

  const renderObfuscatedPhoneNumber = (mobileNumber?: string) => {
    if (!mobileNumber) {
      return "";
    }
    return (
      mobileNumber.substring(0, 5) +
      "*".repeat(mobileNumber.length - 8) +
      mobileNumber.substring(mobileNumber.length - 3)
    );
  };

  const renderActions = (id: number, status: EventRsvpStatus) => {
    let buttons;
    switch (status) {
      case GuestStatus.Booked:
        buttons = (
          <AsyncButton
            color="primary"
            size="sm"
            style={{ width: "100px" }}
            onClick={async () => {
              await updateRsvp
                .mutateAsync({
                  rsvpId: id,
                  status: GuestStatus.Attended,
                })
                .catch(notifyDanger);
              invalidateEvent();
            }}
          >
            Check In
          </AsyncButton>
        );
        break;
      case GuestStatus.Attended:
        buttons = (
          <AsyncButton
            color="warning"
            size="sm"
            style={{ width: "100px" }}
            onClick={async () => {
              await updateRsvp
                .mutateAsync({
                  rsvpId: id,
                  status: GuestStatus.Booked,
                })
                .catch(notifyDanger);
              invalidateEvent();
            }}
          >
            Un-Check
          </AsyncButton>
        );
        break;
    }
    return <div className="d-flex flex-row">{buttons}</div>;
  };

  const renderRsvpList = () => (
    <div className="mt-2 overflow-y-auto">
      {attending.length === 0 && (
        <div className="d-flex justify-content-center" style={{ height: 50 }}>
          No bookings.
        </div>
      )}
      {attending.map((rsvp) => {
        const {
          id,
          status,
          userMember: { firstName, lastName, mobileNumber },
        } = rsvp;
        return (
          <Card style={{ marginTop: 10 }} key={id}>
            <CardBody className="d-flex justify-content-between">
              <Row className="d-flex align-items-center flex-1">
                <Col xs={12} sm={6} className="font-weight-bold">
                  {`${firstName} ${lastName}`}
                </Col>
                <Col xs={12} sm={6} className="text-muted">
                  {renderObfuscatedPhoneNumber(mobileNumber)}
                </Col>
              </Row>
              <Row>
                <Col>{renderActions(id, status)}</Col>
              </Row>
            </CardBody>
          </Card>
        );
      })}
    </div>
  );

  return (
    <div className="content mx-3">
      <Container fluid className="flex max-h-[calc(100vh-4rem)] flex-col">
        <div className="d-flex justify-content-between mb-3 mt-5">
          <div className="d-flex align-items-center">
            <div className="font-weight-bold" style={{ fontSize: "1.25rem" }}>
              Check In
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="font-weight-bold mr-3">Filter:</div>

            <EventCategoryFilter
              category={filter.category}
              fetchCategories={api?.activityApi?.findCategories}
              onChange={(category: { value: number; label: string }) => {
                setFilter((filters) => ({
                  ...filters,
                  category: category.value === null ? null : category,
                }));
                if (category.value) {
                  localStorage.setItem(
                    "kiosk_category_id",
                    category.value.toString(),
                  );
                  localStorage.setItem("kiosk_category_label", category.label);
                } else {
                  localStorage.removeItem("kiosk_category_id");
                  localStorage.removeItem("kiosk_category_label");
                }
              }}
            />
          </div>
        </div>
        <Row>
          <Col>
            <EventSelect
              value={
                event && {
                  label: (
                    <EventCard
                      name={event.event.activity.name}
                      startDate={event.startDate}
                      endDate={event.endDate}
                      isFullDayEvent={event.event.isFullDayEvent}
                      hostName={`${event.event.userEventHost.firstName} ${event.event.userEventHost.lastName}`}
                      bookedCount={event.bookedCount}
                      capacity={event.event.capacity}
                    />
                  ),
                  value: event,
                }
              }
              onChange={({ value }) => {
                setSelectedEventId(value.eventOccurrenceId);
              }}
              filters={{
                dateFrom: moment()
                  .startOf("day")
                  .format(DATE_FORMAT_WITH_SECONDS),
                dateTo: moment().endOf("day").format(DATE_FORMAT_WITH_SECONDS),
                activityCategoryId: filter?.category
                  ? [filter.category.value]
                  : undefined,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h4 className="mb-0">Add yourself:</h4>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <AddUserInput
              isDisabled={isFetching}
              placeholderAvatar={placeholderAvatar}
              fetchUsers={api?.memberApi?.findByFullName}
              onChange={async ({ value }) => {
                if (!event) {
                  return;
                }
                const rsvp = event.eventRsvpList.find(
                  ({ userMember: { id } }) => id === value.id,
                );
                if (rsvp) {
                  await updateRsvp
                    .mutateAsync({
                      rsvpId: rsvp.id,
                      status: GuestStatus.Booked,
                    })
                    .catch(notifyDanger);
                } else {
                  await addAttendeeToRsvp
                    .mutateAsync({
                      userMemberId: value.id,
                      occurrenceId: event.id,
                    })
                    .catch(notifyDanger);
                }
                invalidateEvent();
              }}
              value={null}
              excludeIds={attending.map(({ userMember: { id } }) => id)}
            />
          </Col>
        </Row>

        {event && renderRsvpList()}
      </Container>
    </div>
  );
}

export default CheckIn;
