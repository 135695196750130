import { useCallback, useReducer } from "react";

interface SelectedState<T> {
  readonly selected: T[];
  readonly areAllSelected: boolean;
  readonly excluded: T[];
}

type SelectedAction<T> =
  | {
      payload: T;
      type: "TOGGLE";
    }
  | {
      payload: T;
      type: "SELECT";
    }
  | {
      type: "SELECT_ALL";
      payload: boolean;
    }
  | {
      type: "RESET";
    };

const initialState = {
  selected: [],
  areAllSelected: false,
  excluded: [],
};

function reducer<T>(state: SelectedState<T>, action: SelectedAction<T>) {
  switch (action.type) {
    case "TOGGLE": {
      if (state.areAllSelected) {
        const idx = state.excluded.find((e) => e === action.payload);
        if (idx === undefined) {
          return {
            ...state,
            excluded: [...state.excluded, action.payload],
          };
        }

        return {
          ...state,
          excluded: state.excluded.filter((s) => s !== action.payload),
        };
      } else {
        const idx = state.selected.find((s) => s === action.payload);
        if (idx === undefined) {
          return {
            ...state,
            selected: [...state.selected, action.payload],
          };
        }

        return {
          ...state,
          selected: state.selected.filter((s) => s !== action.payload),
        };
      }
    }
    case "SELECT": {
      if (state.areAllSelected) {
        const idx = state.excluded.find((e) => e === action.payload);
        if (idx === undefined) {
          return {
            ...state,
            excluded: [...state.excluded, action.payload],
          };
        }

        return {
          ...state,
        };
      } else {
        const idx = state.selected.find((s) => s === action.payload);
        if (idx === undefined) {
          return {
            ...state,
            selected: [...state.selected, action.payload],
          };
        }

        return {
          ...state,
        };
      }
    }
    case "SELECT_ALL": {
      return {
        ...state,
        selected: [],
        excluded: [],
        areAllSelected: !state.areAllSelected,
      };
    }
    case "RESET": {
      return { ...initialState };
    }
    default: {
      throw new Error("Unknown action.");
    }
  }
}

export function useSelected<T>() {
  const [state, dispatch] = useReducer(reducer<T>, initialState);

  const toggle = useCallback((id: T) => {
    dispatch({ type: "TOGGLE", payload: id });
  }, []);

  const select = useCallback((id: T) => {
    dispatch({ type: "SELECT", payload: id });
  }, []);

  const selectMultiple = useCallback((ids: T[]) => {
    ids.forEach((id) => {
      dispatch({ type: "SELECT", payload: id });
    });
  }, []);

  const selectAll = useCallback((checked: boolean) => {
    dispatch({ type: "SELECT_ALL", payload: checked });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  const isSelected = useCallback(
    (id: T) => {
      if (state.areAllSelected) {
        return !state.excluded.some((s) => s === id);
      } else {
        return state.selected.some((s) => s === id);
      }
    },
    [state.areAllSelected, state.excluded, state.selected],
  );

  return {
    select,
    toggle,
    selectMultiple,
    selectAll,
    selected: state.selected,
    areAllSelected: state.areAllSelected,
    isSelected,
    excluded: state.excluded,
    reset,
  };
}
