import { useAbility } from "@casl/react";
import { useMutationStripeCompleteOnboard } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import {
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectBalances,
  ConnectComponentsProvider,
  ConnectDocuments,
  ConnectNotificationBanner,
  ConnectPayments,
  ConnectPayoutsList,
} from "@stripe/react-connect-js";
import { useContext, useState } from "react";

import { useStripeConnect } from "../../hooks";
import { AbilityContext, Subject, Verb } from "../../permissions";
import { ModalContext, ModalWrapper, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button } from "../atoms";

export function StripePaymentSettings() {
  const { clubId, stripe_account_type } = useClubSettings();
  const [selectedTab, setSelectedTab] = useState<
    "onboarding" | "account" | "payments" | "balance" | "payouts" | "documents"
  >(stripe_account_type === "CONNECTED" ? "account" : "onboarding");

  const stripeConnectInstance = useStripeConnect();
  const ability = useAbility(AbilityContext);
  const { api } = useGymflowModels();
  const { mutateAsync: completeOnboard } = useMutationStripeCompleteOnboard({
    api,
  });
  const { setModal, hide } = useContext(ModalContext);

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex flex-row gap-x-2 border-b border-gray-200">
        <div
          onClick={() => setSelectedTab("onboarding")}
          className={cn(
            "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
            {
              "text-accent border-accent border-b-2 ":
                selectedTab === "onboarding",
              hidden: stripe_account_type !== "NOT_CONNECTED",
            },
          )}
        >
          Onboarding
        </div>
        <div
          onClick={() => setSelectedTab("account")}
          className={cn(
            "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
            {
              "text-accent border-accent border-b-2 ":
                selectedTab === "account",
              hidden: ability.cannot(Verb.View, Subject.StripeConnect),
            },
          )}
        >
          Account
        </div>
        <div
          onClick={() => setSelectedTab("payments")}
          className={cn(
            "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
            {
              "text-accent border-accent border-b-2 ":
                selectedTab === "payments",
              hidden: ability.cannot(Verb.View, Subject.StripeConnect),
            },
          )}
        >
          Payments
        </div>
        <div
          onClick={() => setSelectedTab("balance")}
          className={cn(
            "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
            {
              "text-accent border-accent border-b-2 ":
                selectedTab === "balance",
              hidden: ability.cannot(Verb.View, Subject.StripeConnect),
            },
          )}
        >
          Balance
        </div>
        <div
          onClick={() => setSelectedTab("payouts")}
          className={cn(
            "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
            {
              "text-accent border-accent border-b-2 ":
                selectedTab === "payouts",
              hidden: ability.cannot(Verb.View, Subject.StripeConnect),
            },
          )}
        >
          Payouts
        </div>
        <div
          onClick={() => setSelectedTab("documents")}
          className={cn(
            "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
            {
              "text-accent border-accent border-b-2 ":
                selectedTab === "documents",
              hidden: ability.cannot(Verb.View, Subject.StripeConnect),
            },
          )}
        >
          Documents
        </div>
      </div>
      <div className="mb-32 flex h-full flex-col overflow-y-auto pr-8">
        {stripeConnectInstance && (
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectNotificationBanner
              collectionOptions={{
                fields: "eventually_due",
                futureRequirements: "include",
              }}
            />
            {selectedTab === "onboarding" && (
              <ConnectAccountOnboarding
                collectionOptions={{
                  fields: "eventually_due",
                  futureRequirements: "include",
                }}
                onExit={async () => {
                  await completeOnboard({ clubId });

                  setModal(
                    <ModalWrapper onCancel={hide}>
                      <div className="text-lg font-semibold text-gray-900">
                        Onboard complete.
                      </div>
                      <div className="mt-2 text-sm text-gray-600">
                        The page will now refresh and you can start using
                        Gymflow with Stripe Payments.
                      </div>
                      <div className="mt-5 flex">
                        <Button
                          intent="primary"
                          onClick={() => {
                            window.location.reload();
                            hide();
                          }}
                        >
                          Ok
                        </Button>
                      </div>
                    </ModalWrapper>,
                  );
                }}
              />
            )}
            {selectedTab === "account" && (
              <ConnectAccountManagement
                collectionOptions={{
                  fields: "eventually_due",
                  futureRequirements: "include",
                }}
              />
            )}
            {selectedTab === "payments" && <ConnectPayments />}
            {selectedTab === "balance" && <ConnectBalances />}
            {selectedTab === "payouts" && <ConnectPayoutsList />}
            {selectedTab === "documents" && <ConnectDocuments />}
          </ConnectComponentsProvider>
        )}
      </div>
    </div>
  );
}
