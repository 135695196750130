import { useAbility } from "@casl/react";
import { useClub, useLead, useMember, useTaskCreate } from "@gymflow/api";
import { AlertContext, NotificationContext } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { LeadDTO, UserMemberBean } from "@gymflow/types";
import React, { useContext } from "react";

import { useAccessToggleButton } from "../../hooks";
import useSendEmails from "../../hooks/useSendEmails";
import { AbilityContext, Subject, Verb } from "../../permissions";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import {
  Button,
  CheckDoneIcon,
  CheckInIcon,
  CheckOutIcon,
  MailIcon,
  ShopBagIcon,
  Spinner,
  StickerSquareIcon,
} from "../atoms";
import TaskAlert from "../Task/TaskAlert";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../UserMember/SendEmails/SendEmailAlert";

export interface ActionMenuButtonsProps {
  userMemberId?: string;
  leadId?: number;
  showShop: (user?: UserMemberBean | LeadDTO) => Promise<void>;
  className?: string;
}

export const ActionMenuButtons: React.FC<ActionMenuButtonsProps> = ({
  userMemberId,
  leadId,
  showShop,
  className,
}) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClub({ api, clubId: settings.clubId });
  const ability = useAbility(AbilityContext);
  const { setAlert, hide } = useContext(AlertContext);
  const createTaskMutation = useTaskCreate({ api, tz: settings.timezone });
  const { sendEmails } = useSendEmails();
  const { notify } = useContext(NotificationContext);
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { data: memberContainer } = useMember({
    api,
    memberId: userMemberId,
    tz: settings.timezone,
  });
  const { data: lead } = useLead({ api, leadId: leadId });
  const user = memberContainer?.user || lead;

  const { toggle, isCheckedIn } = useAccessToggleButton({ userMemberId });

  return (
    <div className={cn("flex flex-row items-center gap-x-2", className)}>
      {!user && <Spinner />}
      {user && (
        <>
          {ability.can(Verb.Create, Subject.Email) && (
            <Button
              onClick={async () => {
                // TODO: Refactor this so that the email sending logic is handled by the email creation component and not it's parent, similar to the strategy for note creation
                setAlert(
                  <SendEmailAlertWithProvider
                    allowMarketing={user.emailCommunication}
                    from={club?.email!}
                    to={`${user.firstName} ${user.lastName}`}
                    onSubmit={(values: any) => {
                      const emailRecipientList = [];
                      if (user.profileType === "LEAD") {
                        emailRecipientList.push({
                          leadId: user.id,
                        });
                      } else {
                        emailRecipientList.push({
                          userMemberId: user.id,
                        });
                      }

                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      return sendEmails(
                        values.subject,
                        values.body,
                        emailRecipientList,
                        values.marketing,
                        bcc,
                      );
                    }}
                    onCancel={hide}
                  />,
                );
              }}
              className="!mt-0 flex h-10 w-10 min-w-0 px-0 shadow"
            >
              <MailIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-700"
              />
            </Button>
          )}
          <Button
            onClick={() => {
              triggerNewNote({
                ...(typeof user.id === "number"
                  ? {
                      leadId: user.id,
                    }
                  : {
                      userMemberId: user.id,
                    }),
                name: `${user.firstName} ${user.lastName}`,
              });
            }}
            className="!mt-0 flex h-10 w-10 min-w-0 px-0 shadow"
          >
            <StickerSquareIcon
              className="h-[1.125rem] w-[1.125rem]"
              pathClassName="stroke-gray-700"
            />
          </Button>
          <Button
            // TODO: Refactor this so that the email sending logic is handled by the email creation component and not it's parent, similar to the strategy for note creation
            onClick={() => {
              setAlert(
                <TaskAlert
                  defaultRelatedUsers={
                    user.profileType !== "LEAD"
                      ? [
                          {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                          },
                        ]
                      : []
                  }
                  defaultRelatedLeads={
                    user.profileType === "LEAD"
                      ? [
                          {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                          },
                        ]
                      : []
                  }
                  onCancel={hide}
                  onSubmit={async (values) => {
                    await createTaskMutation.mutateAsync(values);
                    notify({ message: "Task Created" });
                    hide();
                  }}
                />,
              );
            }}
            className="!mt-0 flex h-10 w-10 min-w-0 px-0 shadow"
          >
            <CheckDoneIcon
              className="h-[1.125rem] w-[1.125rem]"
              pathClassName="stroke-gray-700"
            />
          </Button>
          <Button
            className="!mt-0 flex h-10 w-10 min-w-0 px-0 shadow"
            onClick={() => {
              showShop(user);
            }}
          >
            <ShopBagIcon
              className="h-[1.125rem] w-[1.125rem]"
              pathClassName="stroke-gray-700"
            />
          </Button>

          <Button
            className="!mt-0 flex h-10 w-10 min-w-0 px-0 shadow"
            onClick={toggle}
          >
            {isCheckedIn ? (
              <CheckOutIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-700"
              />
            ) : (
              <CheckInIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-700"
              />
            )}
          </Button>
        </>
      )}
    </div>
  );
};
