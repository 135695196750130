import { useAbility } from "@casl/react";
import {
  useClub,
  useInfiniteQueryMemberList,
  useMutationMemberListCsv,
  useQueryMemberListCount,
  UserMemberListFilter,
} from "@gymflow/api";
import {
  AlertContext,
  isMobile,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { UserMemberListItem } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import { useLocalStorage, useWindowSize } from "usehooks-ts";

import {
  Badge,
  Button,
  Checkbox,
  DownloadIcon,
  FilterIcon,
  MailIcon,
  PlusIcon,
  Spinner,
} from "../components/atoms";
import { QuickActionsButton } from "../components/atoms/QuickActionsButton";
import { NewUserSideBarProviderContext } from "../components/molecules";
import { UserMemberAvatar } from "../components/molecules/UserMemberAvatar";
import { PaginatedTable } from "../components/organisms";
import { SettingsContainer } from "../components/Settings/SettingsContainer";
import SendEmailAlert from "../components/UserMember/SendEmails/SendEmailAlert";
import {
  usePageSize,
  usePortalRoutes,
  useSelected,
  useSendEmailsNew,
} from "../hooks";
import { AbilityContext, Subject, Verb } from "../permissions";
import { useAuthenticatedUser, useClubSettings } from "../providers";
import useGymflowModels from "../store";
import { UserMemberListFilterSidebar } from "./UserMemberListFilterSidebar";

export interface UserMemberListNewProps {}

export const UserMemberListNew: React.FC<UserMemberListNewProps> = () => {
  const ability = useAbility(AbilityContext);
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClub({ api, clubId: settings.clubId });
  const [currentPage, setCurrentPage] = useState(0);
  const { id: loggedInId } = useAuthenticatedUser();
  const { routeId } = usePortalRoutes();
  const [filters, setFilters] = useLocalStorage<UserMemberListFilter>(
    buildUserListFilterLocalStorageKey(loggedInId as string, routeId),
    {},
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const { data, isLoading, fetchNextPage } = useInfiniteQueryMemberList({
    api,
    pagination: {
      limit: pageSize,
    },
    filters,
    tz: settings.timezone,
  });

  const { data: count, isLoading: isLoadingCount } = useQueryMemberListCount({
    api,
    filters,
    tz: settings.timezone,
  });
  const { mutateAsync: downloadCsv } = useMutationMemberListCsv({
    api,
    tz: settings.timezone,
  });

  const [sort, setSort] = React.useState<any>([]);
  const { sendEmailToMembers, sendEmailToMemberFilter } = useSendEmailsNew();

  const columnHelper = createColumnHelper<UserMemberListItem>();
  const {
    toggle,
    selectAll,
    selected,
    areAllSelected,
    excluded,
    isSelected,
    selectMultiple,
    reset,
  } = useSelected<string>();
  const alert = useContext(AlertContext);
  const actionColumn = columnHelper.accessor("userMemberId", {
    id: "actions",
    header: "Actions",
    cell: (cell) => {
      return <QuickActionsButton userMemberId={cell.getValue()} />;
    },
    enableSorting: false,
  });
  const currentPageData = data?.pages[currentPage]?.content ?? [];
  const selectColumn = columnHelper.accessor("userMemberId", {
    id: "selected",
    enableSorting: false,
    header: () => (
      <Checkbox
        className="pr-4"
        value={areAllSelected}
        onChange={function (checked) {
          selectAll(checked);
        }}
      />
    ),
    cell: (cell) => {
      return (
        <Checkbox
          className="pr-4"
          value={isSelected(cell.getValue())}
          onChange={function (_, e) {
            if (e.shiftKey) {
              e.preventDefault();
              e.stopPropagation();
              const previousSelectedId = selected[selected.length - 1];
              if (previousSelectedId !== undefined) {
                const previousSelectedIdx = currentPageData.findIndex(
                  (row) => row.userMemberId === previousSelectedId,
                );
                if (previousSelectedIdx !== -1) {
                  const a =
                    previousSelectedIdx <= cell.row.index
                      ? previousSelectedIdx
                      : cell.row.index;
                  const b =
                    previousSelectedIdx > cell.row.index
                      ? previousSelectedIdx
                      : cell.row.index;

                  const slice = currentPageData
                    .slice(a, b + 1)
                    .map((r) => r.userMemberId);
                  selectMultiple(slice);
                  return;
                }
              }
            }
            toggle(cell.getValue());
          }}
        />
      );
    },
  });
  const desktopColumns = [
    selectColumn,
    columnHelper.accessor("userMemberId", {
      id: "firstName",
      header: "User",
      enableSorting: false,
      cell: (cell) => {
        return <UserMemberAvatar {...cell.row.original} id={cell.getValue()} />;
      },
    }),
    columnHelper.accessor(
      (e) => ({
        membershipName: e.membershipName,
        membershipPrice: e.membershipPrice,
      }),
      {
        id: "membershipName",
        header: "Current Membership",
        enableSorting: false,
        cell: (cell) => {
          if (!cell.getValue().membershipName) return <div>No Membership</div>;
          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">
                {cell.getValue().membershipName}
              </div>
              <div className="text-sm font-normal text-gray-600">
                {formatCurrency(
                  cell.getValue().membershipPrice,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor("membershipStatus", {
      id: "membershipStatus",
      header: "Membership Status",
      enableSorting: false,
      cell: (cell) => {
        if (!cell.getValue()) return "-";
        return (
          <Badge
            intent={
              (
                {
                  ACTIVE: "success",
                  PENDING: "default",
                  PAUSED: "warning",
                  CANCELLED: "default",
                  EXPIRED: "error",
                  DELETED: "error",
                  OVERDUE: "error",
                } as const
              )[cell.getValue()]
            }
          >
            {capitalize(cell.getValue())}
          </Badge>
        );
      },
    }),
    columnHelper.accessor(
      (e) => ({
        creditsRemaining: e.creditsRemaining,
        creditsUnlimited: e.creditsUnlimited,
      }),
      {
        header: "Credits",
        enableSorting: false,
        cell: (cell) => {
          if (!cell.getValue()) return "-";
          if (cell.getValue().creditsUnlimited) return <div>∞</div>;
          if (cell.getValue().creditsRemaining)
            return <div>{cell.getValue().creditsRemaining}</div>;
          return "-";
        },
      },
    ),
    actionColumn,
  ];
  const mobileColumns = [
    selectColumn,
    columnHelper.accessor("userMemberId", {
      id: "firstName",
      header: "User",
      enableSorting: false,
      cell: (cell) => {
        return (
          <UserMemberAvatar
            className="w-full"
            {...cell.row.original}
            id={cell.getValue()}
          />
        );
      },
    }),
    actionColumn,
  ];
  const windowSize = useWindowSize({ debounceDelay: 100 });
  const [filtersAreVisible, setFiltersAreVisible] = useState(false);
  const { open } = useContext(NewUserSideBarProviderContext);
  const selectionCount = areAllSelected
    ? (count?.count ?? 0) - excluded.length
    : selected.length;
  return (
    <div className="flex h-full max-h-full w-full p-4 lg:p-8">
      <UserMemberListFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={(newFilters) => {
          setCurrentPage(0);
          setFilters(newFilters);
        }}
      />
      <SettingsContainer
        title={
          isLoadingCount ? (
            <Spinner />
          ) : (
            `${count?.count} Users ${
              selectionCount > 0 ? "(" + selectionCount + " selected)" : ""
            }`
          )
        }
        subTitle="All users that have accounts setup."
        actions={
          <>
            <Button
              onClick={async () => await downloadCsv(filters)}
              className="mt-0 w-11 min-w-[2.75rem]"
            >
              <DownloadIcon pathClassName="stroke-gray-500" />
            </Button>
            <Button
              className={cn("mt-0 w-11 min-w-[2.75rem]", {
                hidden:
                  (selected.length === 0 && !areAllSelected) ||
                  !ability.can(Verb.Create, Subject.Email),
              })}
              onClick={() => {
                alert.setAlert(
                  <SendEmailAlert
                    allowMarketing
                    from={club?.email ?? ""}
                    to={`${selectionCount} ${pluralize(
                      "User",
                      "Users",
                      selectionCount,
                    )}`}
                    onSubmit={async (values) => {
                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      if (areAllSelected) {
                        await sendEmailToMemberFilter({
                          emailPayload: {
                            marketing: values.marketing,
                            body: values.body,
                            subject: values.subject,
                            bccList: bcc,
                          },
                          userMemberIdsToExclude: excluded,
                          createdFrom: filters?.createdFrom
                            ? moment(filters.createdFrom, "YYYY-MM-DD")
                                .startOf("day")
                                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                            : undefined,
                          createdTo: filters?.createdTo
                            ? moment(filters.createdTo, "YYYY-MM-DD")
                                .endOf("day")
                                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                            : undefined,
                          smsCommunication: filters?.smsCommunication,
                          emailCommunication: filters?.emailCommunication,
                          membershipIdList: filters?.membershipId,
                          membershipStatusList: filters?.membershipStatus,
                          membershipTypeList: filters?.membershipType,
                          membershipStartFrom: filters?.membershipStartFrom
                            ? moment(filters.membershipStartFrom, "YYYY-MM-DD")
                                .startOf("day")
                                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                            : undefined,
                          membershipStartTo: filters?.membershipStartTo
                            ? moment(filters.membershipStartTo, "YYYY-MM-DD")
                                .endOf("day")
                                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                            : undefined,
                          membershipCancellationFrom:
                            filters?.membershipCancellationFrom
                              ? moment(
                                  filters.membershipCancellationFrom,
                                  "YYYY-MM-DD",
                                )
                                  .startOf("day")
                                  .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                              : undefined,
                          membershipCancellationTo:
                            filters?.membershipCancellationTo
                              ? moment(
                                  filters.membershipCancellationTo,
                                  "YYYY-MM-DD",
                                )
                                  .endOf("day")
                                  .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                              : undefined,
                          membershipExpireFrom: filters?.membershipExpireFrom
                            ? moment(filters.membershipExpireFrom, "YYYY-MM-DD")
                                .startOf("day")
                                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                            : undefined,
                          membershipExpireTo: filters?.membershipExpireTo
                            ? moment(filters.membershipExpireTo, "YYYY-MM-DD")
                                .endOf("day")
                                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                            : undefined,
                          assignedStaffIdList: filters?.assignedStaffId,
                          creditsRemainingFrom: filters?.creditsRemainingFrom,
                          creditsRemainingTo: filters?.creditsRemainingTo,
                        });
                      } else {
                        await sendEmailToMembers({
                          emailPayload: {
                            marketing: values.marketing,
                            body: values.body,
                            subject: values.subject,
                            bccList: bcc,
                          },
                          userMemberIds: selected,
                        });
                      }
                      reset();
                    }}
                    onCancel={alert.hide}
                  />,
                );
              }}
            >
              <MailIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
            </Button>

            <Button
              className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
              onClick={() => setFiltersAreVisible(true)}
            >
              <div className="inline-flex items-center gap-x-2">
                <FilterIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-gray-500"
                />
                <div className={cn("hidden lg:flex")}>Filters</div>
              </div>
            </Button>
            <Button
              className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
              onClick={() => {
                open();
              }}
              intent="secondary"
            >
              <div className="inline-flex items-center gap-x-2">
                <PlusIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-white"
                />
                <div className={cn("hidden lg:flex")}>User</div>
              </div>
            </Button>
          </>
        }
      >
        <PaginatedTable
          tableProps={{
            data: currentPageData,
            columns: !isMobile(windowSize.width)
              ? desktopColumns
              : mobileColumns,
            onSortingChange: setSort,
            sort: sort,
            isFetching: isLoading,
            pageSize: pageSize,
            tableContainerRef,
          }}
          hasPreviousPage={!!data && currentPage > 0}
          hasNextPage={!!data && !!data.pages[currentPage]?.nextPageToken}
          goToNextPage={() => {
            setCurrentPage((e) => e + 1);
            if (!data?.pages[currentPage + 1]) {
              fetchNextPage();
            }
          }}
          goToPreviousPage={() => {
            setCurrentPage((e) => e - 1);
          }}
        />
      </SettingsContainer>
    </div>
  );
};

function buildUserListFilterLocalStorageKey(userId: string, routeId: string) {
  return `member-list-${userId}-${routeId}`;
}
