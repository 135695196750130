import { leadSourceListQueryFn } from "@gymflow/api";
import {
  createUserFormSchema,
  formikHelpers,
  FormikInput,
  MobileNumberInput,
  renderErrorRowOnTouch,
  UserDetailsFormMapper,
  useRecordForm,
  UserFormField,
} from "@gymflow/common";
import classNames from "classnames";
import { useFormik } from "formik";
import noop from "lodash/noop";
import omit from "lodash/omit";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { Form, FormGroup } from "reactstrap";

import { ModalContext, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, PaginatedSelect } from "../atoms";
import GenderSelect from "../atoms/GenderSelect";
import { LeadSourceModal } from "../organisms";
import useStaffOnUserSubmit from "../SalesWizard/useStaffOnUserSubmit";

const { NATIONALITY, EMAIL_COMMUNICATION, SMS_COMMUNICATION } = UserFormField;

export type SideBarUserFormProps = {
  hide: () => void;
  onMemberCreated: (newUserId: string) => void;
  onChange: (newValue: any) => void;
  defaultValues: any;
  requiredFields: any;
};

export const SideBarUserForm: React.FC<SideBarUserFormProps> = ({
  hide,
  onMemberCreated,
  onChange,
  defaultValues,
  requiredFields,
}) => {
  const settings = useClubSettings();
  const postCodeFormat = settings.postal_code_country;
  const defaultNationality = settings.default_nationality;
  const dateFormat = settings.date_format;
  const { api } = useGymflowModels();
  const findByEmail = useCallback(
    async (email: string) => {
      const response = await api.memberApi.findByEmail(email);
      if (response) {
        return "Email already exists.";
      }
      return false;
    },
    [api.memberApi],
  );

  const clubId = settings.clubId;

  const onUserSubmit = useStaffOnUserSubmit({
    create: api.memberApi.create,
    update: api.memberApi.update,
    clubId,
  });
  const onSubmit = async ({ values, isValid }: any) => {
    if (!isValid) {
      return;
    }
    return await onUserSubmit({
      user: {
        ...values,
        profileType: undefined,
        id: undefined,
        isMiniUser: false,
      },
      wasEdited: true,
    });
  };
  const validationSchema = useMemo(
    () =>
      createUserFormSchema({
        postCodeFormat,
        defaultNationality,
        dateFormat,
        findByEmail,
        requiredFields,
        mustAgreeToClubWaiver: false,
      }),
    [
      postCodeFormat,
      defaultNationality,
      dateFormat,
      findByEmail,
      requiredFields,
    ],
  );

  const { initialValues, getValues } = useRecordForm({
    record: {
      ...(requiredFields.mobileNumber
        ? defaultValues
        : omit(defaultValues, ["mobile-number"])),
      [NATIONALITY]: settings.default_nationality,
      [EMAIL_COMMUNICATION]: false,
      [SMS_COMMUNICATION]: false,
    },
    fields: validationSchema.default({}),
    mapper: new UserDetailsFormMapper({
      dateFormat,
    }),
  });
  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: noop,
  });

  useEffect(() => {
    onChange(formikProps.values);
  }, [formikProps.values, onChange]);

  const { errorClass } = formikHelpers(formikProps);
  const { setModal, hide: hideModal } = useContext(ModalContext);

  return (
    <>
      <div className="mt-4 flex h-full overflow-y-auto px-6">
        <Form className="flex w-full flex-col" role="form">
          <div className="mb-1">Email</div>
          <FormGroup
            className={classNames(
              "after:!hidden",
              errorClass(UserFormField.EMAIL),
            )}
          >
            <FormikInput
              name={UserFormField.EMAIL}
              placeholder="Enter Email"
              type="email"
              data-testid={UserFormField.EMAIL}
              autoComplete="off"
              maxLength={128}
              formikProps={formikProps}
              className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
            />
          </FormGroup>
          <div className="mb-1">First Name</div>
          <FormGroup
            className={classNames(
              "after:!hidden",
              errorClass(UserFormField.FIRST_NAME),
            )}
          >
            <FormikInput
              name={UserFormField.FIRST_NAME}
              placeholder="Enter First Name"
              id={UserFormField.FIRST_NAME}
              type="text"
              maxLength={128}
              formikProps={formikProps}
              className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
            />
          </FormGroup>
          <div className="mb-1">Last Name</div>
          <FormGroup
            className={classNames(
              "after:!hidden",
              errorClass(UserFormField.LAST_NAME),
            )}
          >
            <FormikInput
              placeholder="Enter Last Name"
              name={UserFormField.LAST_NAME}
              id={UserFormField.LAST_NAME}
              type="text"
              formikProps={formikProps}
              className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
            />
          </FormGroup>
          {requiredFields.mobileNumber && (
            <>
              <div className="mb-1">Phone</div>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.MOBILE_NUMBER),
                )}
              >
                <FormikInput
                  name={UserFormField.MOBILE_NUMBER}
                  placeholder="Enter Mobile Number"
                  component={MobileNumberInput}
                  type="tel"
                  id={UserFormField.MOBILE_NUMBER}
                  maxLength={32}
                  formikProps={formikProps}
                  phone_number_country={settings.phone_number_country}
                  className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                />
              </FormGroup>
            </>
          )}
          {requiredFields.dateOfBirth && (
            <>
              <div className="mb-1">Date of Birth</div>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.DATE_OF_BIRTH),
                )}
              >
                <FormikInput
                  name={UserFormField.DATE_OF_BIRTH}
                  type="text"
                  id={UserFormField.DATE_OF_BIRTH}
                  maxLength={dateFormat.length}
                  formikProps={formikProps}
                  placeholder={dateFormat}
                  className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                />
              </FormGroup>
            </>
          )}
          {requiredFields.gender && (
            <>
              <div className="mb-1">Gender</div>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.GENDER),
                )}
              >
                <GenderSelect
                  onChange={(newGender: any) =>
                    formikProps.setFieldValue(UserFormField.GENDER, newGender)
                  }
                  value={formikProps.values[UserFormField.GENDER]}
                />
              </FormGroup>
            </>
          )}

          {(requiredFields.addressLine ||
            requiredFields.city ||
            requiredFields.postCode) && <div className="mb-1">Address</div>}
          {requiredFields.addressLine && (
            <>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.ADDRESS1),
                )}
              >
                <FormikInput
                  name={UserFormField.ADDRESS1}
                  placeholder="Address 1"
                  type="text"
                  id={UserFormField.ADDRESS1}
                  maxLength={128}
                  formikProps={formikProps}
                  data-testid={UserFormField.ADDRESS1}
                  className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                />
              </FormGroup>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.ADDRESS2),
                )}
              >
                <FormikInput
                  name={UserFormField.ADDRESS2}
                  placeholder="Address 2"
                  data-testid={UserFormField.ADDRESS2}
                  type="text"
                  maxLength={128}
                  formikProps={formikProps}
                  className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                />
              </FormGroup>
            </>
          )}
          {(requiredFields.postCode || requiredFields.city) && (
            <div className="flex flex-row">
              {requiredFields.city && (
                <FormGroup
                  className={classNames(
                    "after:!hidden",
                    errorClass(UserFormField.CITY),
                  )}
                >
                  <FormikInput
                    name={UserFormField.CITY}
                    placeholder="City"
                    type="text"
                    id={UserFormField.CITY}
                    maxLength={128}
                    formikProps={formikProps}
                    className="mr-2 !h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                  />
                </FormGroup>
              )}
              {requiredFields.postCode && (
                <FormGroup
                  className={classNames(
                    "after:!hidden",
                    errorClass(UserFormField.POST_CODE),
                  )}
                >
                  <FormikInput
                    name={UserFormField.POST_CODE}
                    placeholder="Postcode/Zip"
                    type="text"
                    id={UserFormField.POST_CODE}
                    maxLength={16}
                    formikProps={formikProps}
                    className={classNames(
                      "!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500",
                      {
                        "ml-2": requiredFields.city,
                      },
                    )}
                  />
                </FormGroup>
              )}
            </div>
          )}

          {requiredFields.personalNumber && (
            <>
              <div className="mb-1">
                {settings.default_nationality === "ESP"
                  ? "DNI/Passport"
                  : "ID Number"}
              </div>
              <div className="flex flex-row">
                <FormGroup
                  className={classNames(
                    "after:!hidden",
                    errorClass(UserFormField.PERSONAL_NUMBER),
                  )}
                >
                  <FormikInput
                    name={UserFormField.PERSONAL_NUMBER}
                    placeholder={
                      settings.default_nationality === "ESP"
                        ? "DNI/Passport"
                        : "ID Number"
                    }
                    type="text"
                    id={UserFormField.PERSONAL_NUMBER}
                    maxLength={25}
                    formikProps={formikProps}
                    className="mr-2 !h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                  />
                </FormGroup>
              </div>
            </>
          )}

          <div className="mb-1">How did you hear about us?</div>

          <FormGroup
            className={classNames(
              "dropdown-group",
              errorClass(UserFormField.SOURCE),
            )}
            data-testid={UserFormField.SOURCE}
          >
            <PaginatedSelect
              showMenuFooterButton
              menuFooterClick={async () => {
                setModal(<LeadSourceModal onCancel={hideModal} />);
              }}
              menuFooterText="Create Lead Source"
              refetchOnMenuOpen
              placeholder="Select Source"
              loadOptions={async (_, __, { page }) => {
                const data = await leadSourceListQueryFn({
                  api,
                  opts: {
                    page,
                    extraParams: { active: true },
                  },
                });

                return {
                  options: data.content.map((source) => ({
                    label: source.name,
                    value: source,
                  })),
                  hasMore: !data.last,
                  additional: {
                    page: page + 1,
                  },
                };
              }}
              onChange={(value) => {
                formikProps.setFieldValue("source", {
                  name: value.value.name,
                  id: value.value.id,
                });
              }}
              value={
                formikProps.values.source
                  ? {
                      value: formikProps.values.source,
                      label: formikProps.values.source.name,
                    }
                  : undefined
              }
            />
            {renderErrorRowOnTouch(
              UserFormField.SOURCE,
              formikProps.touched,
              formikProps.errors,
            )}
          </FormGroup>
          {requiredFields.emergencyContact && (
            <>
              <div className="mb-1">Emergency Contact Name</div>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.EMERGENCY_CONTACT_NAME),
                )}
              >
                <FormikInput
                  name={UserFormField.EMERGENCY_CONTACT_NAME}
                  type="text"
                  id={UserFormField.EMERGENCY_CONTACT_NAME}
                  maxLength={128}
                  formikProps={formikProps}
                  className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
                  placeholder="Enter Emergency Contact Name"
                />
              </FormGroup>

              <div className="mb-1">Emergency Contact Phone</div>
              <FormGroup
                className={classNames(
                  "after:!hidden",
                  errorClass(UserFormField.EMERGENCY_CONTACT),
                )}
              >
                <FormikInput
                  name={UserFormField.EMERGENCY_CONTACT}
                  component={MobileNumberInput}
                  id={UserFormField.EMERGENCY_CONTACT}
                  type="tel"
                  maxLength={32}
                  formikProps={formikProps}
                  placeholder="Enter Emergency Contact Number"
                  phone_number_country={settings.phone_number_country}
                />
              </FormGroup>
            </>
          )}
          <FormGroup
            check
            className={errorClass(UserFormField.EMAIL_COMMUNICATION)}
          >
            <div className="flex flex-row items-center">
              <input
                name={UserFormField.EMAIL_COMMUNICATION}
                type="checkbox"
                id={UserFormField.EMAIL_COMMUNICATION}
                data-testid="email-communication"
                checked={formikProps.values[UserFormField.EMAIL_COMMUNICATION]}
                onChange={({ target: { checked } }) => {
                  formikProps.setFieldValue(
                    UserFormField.EMAIL_COMMUNICATION,
                    checked,
                  );
                }}
                className="!visible !relative mr-2 h-4 w-4 rounded-md border-gray-200 bg-gray-100 !opacity-100"
              />
              Keep in touch via Email
            </div>
          </FormGroup>
          <FormGroup
            check
            className={errorClass(UserFormField.SMS_COMMUNICATION)}
          >
            <div className="flex flex-row items-center">
              <input
                name={UserFormField.SMS_COMMUNICATION}
                type="checkbox"
                data-testid="sms-communication"
                checked={formikProps.values[UserFormField.SMS_COMMUNICATION]}
                onChange={({ target: { checked } }) => {
                  formikProps.setFieldValue(
                    UserFormField.SMS_COMMUNICATION,
                    checked,
                  );
                }}
                className="!visible !relative mr-2 h-4 w-4 rounded-md border-gray-200 bg-gray-100 !opacity-100"
              />
              Keep in touch via SMS
            </div>
          </FormGroup>
        </Form>
      </div>

      <div className="flex h-20 flex-row items-center justify-end gap-2 border-t border-gray-200 px-6 py-4">
        <Button
          className="w-full"
          onClick={() => {
            formikProps.resetForm();
            hide();
          }}
        >
          Cancel
        </Button>
        <Button
          intent="primary"
          className="w-full"
          onClick={async () => {
            await formikProps.submitForm();
            const errors = await formikProps.validateForm();
            if (Object.keys(errors).length > 0) {
              await onSubmit({ isValid: false });
              return;
            }

            const result = (await onSubmit({
              values: getValues(formikProps.values),
              isValid: true,
            })) as any;
            formikProps.resetForm();
            hide();
            onMemberCreated(result.id);
          }}
        >
          Create
        </Button>
      </div>
    </>
  );
};
