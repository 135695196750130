import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

import { useAccessToggleButton } from "../../hooks";
import { Button } from "./Button";
import { UserCheckInIcon } from "./icons";
import { Spinner } from "./Spinner";

export interface CheckInButtonProps {
  userMemberId: string;
  hideIfCheckedOut?: boolean;
  hideCheckInText?: boolean;
  onCheckInOutFinished?: (isCheckedIn: boolean) => void;
}

export const CheckInButton: React.FC<CheckInButtonProps> = ({
  userMemberId,
  hideIfCheckedOut,
  hideCheckInText,
  onCheckInOutFinished,
}) => {
  const { toggle, isReady, checkedInOrOut, isCheckedIn, didCheckIn } =
    useAccessToggleButton({ userMemberId, onCheckInOutFinished });

  if (!isReady) return <Spinner />;
  if (!isCheckedIn && hideIfCheckedOut) return null;
  return (
    <Button
      disabled={checkedInOrOut}
      onClick={toggle}
      className={classNames("!mt-0 flex", {
        "bg-success-600/10 !text-success-600 !ring-success-600/40 !border-success-600/20 hover:bg-success-600/10 hover:!text-success-600 hover:!ring-success-600/40 hover:!border-success-600/20 !cursor-default":
          checkedInOrOut,
      })}
      title={
        !checkedInOrOut
          ? isCheckedIn
            ? "Check Out"
            : "Check In"
          : didCheckIn
          ? "Checked In"
          : "Checked Out"
      }
    >
      <div className="flex min-w-fit flex-row items-center gap-x-2">
        {!checkedInOrOut && (
          <>
            <UserCheckInIcon className="h-6" />
            {hideCheckInText ? "" : isCheckedIn ? "Check Out" : "Check In"}
          </>
        )}
        {checkedInOrOut && (
          <>
            <FontAwesomeIcon icon={faCheck} className="text-success-600" />
            {hideCheckInText ? "" : didCheckIn ? "Checked In" : "Checked Out"}
          </>
        )}
      </div>
    </Button>
  );
};
