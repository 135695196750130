import { useRuleValues } from "@gymflow/api";
import forIn from "lodash/forIn";
import { useMemo } from "react";

import { RuleName } from "../constants/RuleName";

export function useUserFormFieldConfiguration(
  { api, clubId },
  { enabled } = { enabled: true },
) {
  const {
    data: fetchedValues,
    isFetching,
    isLoading,
  } = useRuleValues({ api, clubId }, { enabled });
  const ruleValues = useMemo(() => {
    const fieldValueRuleConfig = fetchedValues.find(
      (v) => v.ruleType === RuleName.UserFormRequirementConfig,
    );
    if (fieldValueRuleConfig) {
      const values = {};
      forIn(fieldValueRuleConfig.userFormRule, (value, key) => {
        values[key] = value.isRequired;
      });
      return values;
    }
    return {};
  }, [fetchedValues]);

  return { isFetching, data: ruleValues, isLoading };
}
