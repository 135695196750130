import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { InvoiceDTO, ProductOrderDetailDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { productQueryKeys } from "../product";
import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationMemberSale({
  api,
  memberId,
  clubId,
  tz,
}: {
  api: {
    memberApi: {
      checkout: (memberId: string, clubId: number, params: any) => Promise<any>;
    };
    productApi: {
      order: (params: {
        userMemberId: string;
        paymentMethodId?: string;
        products: ProductOrderDetailDTO[];
        promotionCode?: string;
        priceOverride?: number;
      }) => Promise<any>;
    };
  };
  tz: string;
  memberId: string;
  clubId: number;
}) {
  const queryClient = useQueryClient();
  const result = useMutation({
    mutationFn: async (
      params: {
        paymentMethod?: string;
        promotionCode?: string;
        priceOverride?: number;
      } & (
        | {
            startDate: string;
            membershipId: number;
          }
        | {
            startDate: string;
            sessionPackId: number;
          }
        | {
            products: ProductOrderDetailDTO[];
          }
      ),
    ): Promise<{ invoice: InvoiceDTO }> => {
      if ("products" in params) {
        return (
          await api.productApi.order({
            paymentMethodId: params.paymentMethod,
            promotionCode: params.promotionCode,
            products: params.products,
            userMemberId: memberId!,
            priceOverride: params.priceOverride,
          })
        ).data;
      } else {
        return (
          await api.memberApi.checkout(memberId!, clubId, {
            paymentMethod: params.paymentMethod,
            subscriptionSignUpBean: {
              priceOverride: params.priceOverride,
              ...("membershipId" in params
                ? {
                    membershipId: params.membershipId,
                  }
                : {
                    sessionPackId: params.sessionPackId,
                  }),
              promotionCode: params.promotionCode,
              startDate:
                DateTime.fromFormat(params.startDate, LUXON_DATE_FORMAT, {
                  zone: tz,
                })
                  .startOf("day")
                  .setZone("UTC")
                  .toISO()
                  ?.split(".")[0] + "Z",
            },
          })
        ).data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: productQueryKeys.all(),
      });
    },
  });

  return result;
}
