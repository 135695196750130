import check, { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canActivateAndDeactivate,
  canFind,
  canTrimFields,
} from "./features/apiResource";
import ServiceType from "./ServiceType";

const serviceEndpoints = {
  recurring: `${endpoints.service}/recurring`,
  prepaid: `${endpoints.service}/prepaid`,
  summary: `${endpoints.service}/summary`,
};

const serviceApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["name", "currency"],
    apiUrl,
    baseUrl: `${clubPrefix}${endpoints.service}`,
    http: axiosInstance,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canActivateAndDeactivate(state),
    canFind(state),
    {
      findById(id, proRataStartDate) {
        assert(
          check.any([check.string(id), check.number(id)]),
          "id must be number or string",
        );

        let config;
        if (proRataStartDate) {
          config = {
            params: {
              startDate: proRataStartDate,
            },
          };
        }

        return state.http.get(`${state.baseUrl}/${id}`, config);
      },
      createRecurring(newService, termsAndConditionsBlob) {
        return state.create(
          ServiceType.Recurring,
          { ...newService, clubIdList: [clubId] },
          termsAndConditionsBlob,
        );
      },

      createPrePaid(newService, termsAndConditionsBlob) {
        return state.create(
          ServiceType.Prepaid,
          { ...newService, clubIdList: [clubId] },
          termsAndConditionsBlob,
        );
      },

      /**
       * @private
       */
      create(serviceType, newService, termsAndConditionsBlob) {
        const formData = state.transformIntoFormData({
          file: termsAndConditionsBlob,
          service: newService,
        });

        let endpoint;
        switch (serviceType) {
          case ServiceType.Recurring:
            endpoint = serviceEndpoints.recurring;
            break;
          case ServiceType.Prepaid:
            endpoint = serviceEndpoints.prepaid;
            break;
          default:
            throw new Error("Service type unknown");
        }

        return state.http.post(`${clubPrefix}${endpoint}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },

      update(id, { patchedFields, termsAndConditionsBlob }) {
        assert(
          check.any([check.string(id), check.number(id)]),
          "id must be number or string",
        );
        const formData = state.transformIntoFormData({
          file: termsAndConditionsBlob,
          service: patchedFields,
        });

        return state.http.patch(`${state.baseUrl}/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },

      /**
       * @private
       */
      transformIntoFormData({ file, service }) {
        const formData = new FormData();
        const trimmedData = state.trimFields(service);
        if (file) {
          formData.append("file", file, file.name);
        }

        if (service) {
          formData.append(
            "membership",
            new Blob([JSON.stringify(trimmedData)], {
              type: "application/json",
            }),
          );
        }

        return formData;
      },

      findByClubId(clubId, { page, limit, status, extraParams, type, sort }) {
        assert.number(clubId, "clubId must be a number");
        return state.find({
          page,
          limit,
          sort,
          extraParams: {
            type,
            status,
            ...extraParams,
            "clubList.id": clubId,
          },
        });
      },

      summary({ membershipId, startDate, promotionCode, userMemberId }) {
        assert.number(membershipId, "membershipId must be a number");
        assert.string(startDate, "startDate must be a valid datetime");
        assert.maybe.string(promotionCode, "promotion code must be a string");
        assert.maybe.string(userMemberId, "userMemberId must be a string");

        return state.http.post(`${clubPrefix}${serviceEndpoints.summary}`, {
          membershipId: membershipId,
          startDate,
          promotionCode,
          userMemberId,
        });
      },
      delete(id) {
        return state.http.delete(`${state.baseUrl}/${id}`);
      },
      getStatusHistory(memberId) {
        assert.string(memberId, "memberId must be a string");
        return state.http.get(
          `${clubPrefix}user/member/${memberId}/membership-history`,
        );
      },
      calculateCancelDates(filter) {
        return state.http.put(
          `${state.baseUrl}/calculate-cancel-dates`,
          filter,
        );
      },
    },
  );
};

export default serviceApi;
