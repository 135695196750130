import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNote } from "@gymflow/api";
import { FormMapper, useRecordForm } from "@gymflow/common";
import { Formik, useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";

import useGymflowModels from "../../../store";
import {
  Badge,
  Button,
  FormikTextAreaInput,
  SlideSideBar,
  UploadArea,
} from "../../atoms";

export const NoteFormSideBar = ({
  onChange,
  onClose,
  isOpen,
  noteId,
  name,
}: {
  onChange: ({ id, fields }: { id?: number; fields: any }) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  noteId?: number;
  name?: string;
}) => {
  const { api } = useGymflowModels();

  const { data: existingNote, isFetching } = useNote({ api, noteId });
  const { initialValues, getValues } = useRecordForm({
    fields: schema.getDefault(),
    record: {
      content: existingNote?.content,
      attachment: existingNote?.attachment,
      attachmentFilename: existingNote?.attachmentFilename,
    },
    mapper,
  });

  return (
    <SlideSideBar isOpen={isOpen} hide={onClose} isLoading={isFetching}>
      <div className="flex h-full flex-col overflow-hidden">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values, formikProps) => {
            if (noteId) {
              const patchedFields = getValues(values);
              await onChange({
                id: noteId,
                fields: patchedFields,
              });
            } else {
              const newValues = getValues(values);
              await onChange({ fields: newValues });
            }
            onClose();
            formikProps.resetForm();
          }}
          validationSchema={schema}
        >
          {(formik) => (
            <>
              <div className="flex-1 overflow-y-auto">
                <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
                  <div className="mb-1 flex flex-row items-center justify-between">
                    <div className="text-xl font-semibold text-gray-900">
                      {noteId ? "Edit Note" : "Create Note"}
                    </div>
                    <FontAwesomeIcon
                      onClick={() => {
                        onClose();
                      }}
                      className="cursor-pointer text-xl text-gray-600"
                      icon={faClose}
                    />
                  </div>
                  <div className="text-sm font-medium text-gray-600">
                    You&apos;re {noteId ? "editing" : "adding"} notes on{" "}
                    <span className="font-bold">{name}</span>
                    &apos;s profile.
                  </div>
                </div>
                <NoteForm />
              </div>
              <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
                <Button onClick={() => onClose()} className="mr-2 w-full">
                  Cancel
                </Button>
                <Button
                  intent="primary"
                  className="w-full"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  Apply
                </Button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </SlideSideBar>
  );
};

function NoteForm() {
  const formikProps = useFormikContext();
  const values = formikProps.values as any;
  const textAreaRef = useRef<any>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.cssText =
        "height:" + textAreaRef.current.scrollHeight + "px";
    }
  }, []);

  return (
    <div className="mx-6">
      <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
        Note *
      </div>
      <FormikTextAreaInput
        name={CONTENT}
        id={CONTENT}
        placeholder="Enter your note text"
        maxLength={4000}
        innerRef={(ref) => {
          textAreaRef.current = ref;
        }}
      />
      <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
        Attach File
      </div>
      {values.file && values.file.removed !== true ? (
        <Badge>
          <a href={values.file.url}>{values.file.name}</a>

          <FontAwesomeIcon
            onClick={() => {
              formikProps.setFieldValue(FILE, { removed: true });
            }}
            className="ml-3 cursor-pointer"
            icon={faClose}
          />
        </Badge>
      ) : (
        <UploadArea
          onChange={(file: any) => {
            formikProps.setFieldValue(FILE, file);
          }}
          allowedExtensions={[
            "pdf",
            "doc",
            "docx",
            "png",
            "jpeg",
            "jpg",
            "svg",
          ]}
          loadHandler={uploadAreaDocumentLoadHandler}
          description={`Documents, PDFs and images only (max 5mb)`}
          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*"
        />
      )}
    </div>
  );
}

export function uploadAreaDocumentLoadHandler({
  setError,
  onChange,
  reader,
  file,
}: {
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  onChange: ({
    name,
    blob,
    file,
  }: {
    name: string;
    blob: string | ArrayBuffer | null;
    file: File;
  }) => void;
  reader: FileReader;
  file: File;
}) {
  const maxFilesize = 5 * 1024 * 1024;
  if (file.size > maxFilesize) {
    setError("File is too big.");
    return;
  }
  setError(null);
  onChange({ name: file.name, blob: reader.result, file });
}

const CONTENT = "content";
const FILE = "file";
const schema = Yup.object().shape({
  [CONTENT]: Yup.string().required().min(3).max(4000),
  [FILE]: Yup.mixed().default(null),
});

const mapper = new FormMapper({
  inValue: [
    {
      key: "attachment",
      transform: (
        attachment: unknown,
        { attachmentFilename }: { attachmentFilename: string },
      ) => {
        if (attachment) {
          return {
            name: attachmentFilename,
            url: attachment,
          };
        }
        return null;
      },
    },
  ],
  inKey: {
    attachment: "file",
  },
  outValue: [
    {
      key: "file",
      transform: (value: unknown) => {
        if (value === null) {
          return undefined;
        }
        return value;
      },
    },
  ],
});
